<template>
  <div>
    <template v-for="(segment, segmentIndex) of flatSegmentSelected">
      <!-- v-if="['VN1A', 'VU', 'VJ', 'VZ', 'AK', 'QH', 'TR', 'JQ'].includes(segment.source)" -->
      <b-card
        :key="segmentIndex"
        header-bg-variant="light-info bg-light-primary"
        header-class="info"
        body-class="py-75 px-0"
        class="border-secondary"
      >
        <app-collapse type="card">
          <app-collapse-item
            title
            body-class="px-0"
          >
            <template #header>
              <div class="d-flex-between w-100">
                <div>
                  <IAmLogoAirline
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    size="sm"
                    custom-class="mr-50"
                  />
                  <span class="font-weight-bolder font-medium-1 ml-1">
                    {{ $t('flight.flightSegment') }}:
                    <span class="text-airline fw-800">{{ segment.segmentFlights }}</span>
                    <!-- <i
                      v-if="isEmpty(getSeatsMap(segment)?.seatFormat)"
                      class="text-warning ml-1"
                    >
                      {{ $t('flight.notSupportSeat') }}
                    </i> -->
                  </span>
                </div>

                <b-spinner
                  v-if="loading"
                  variant="info"
                  class="mr-1"
                />
              </div>
            </template>

            <b-alert
              v-if="['QH'].includes(segment.source)"
              show
              variant="danger"
              class="py-50 px-1 shadow-sm fw-700 d-flex-center my-75"
            >
              <b>Bamboo Airways không hỗ trợ mua ghế khi thanh toán ngay, đại lý vui lòng chọn giữ chỗ nếu cần chọn ghế.</b>
            </b-alert>

            <b-row class="mx-0 pt-1 border-top-secondary justify-content-center">
              <b-col
                cols="12"
                lg="5"
                class="position-relative"
                style="max-width: 400px;"
              >
                <div
                  class="position-sticky"
                  style="top: 70px"
                >
                  <b-table
                    class="text-center border mb-1"
                    striped
                    hover
                    responsive
                    :items="filterSeatBySegment(segment)"
                    :fields="selectedSeatFields"
                    show-empty
                    :empty-text="$t('noMatchingResult')"
                  >
                    <template #cell(passenger)="data">
                      <span class="text-truncate">
                        {{ data.item.passenger.paxType }} #{{ data.index + 1 }}
                      </span>
                    </template>
                    <template #cell(seat)="{item}">
                      <div style="">
                        <b-button
                          v-if="item.seat"
                          variant="success"
                          class="px-75"
                          :disabled="true"
                        >
                          {{ item.seat.seat.seatName }}
                        </b-button>
                        <span v-else> - </span>
                      </div>
                    </template>
                    <template #cell(price)="{item}">
                      <span
                        v-if="
                          item.seat &&
                            item.seat.seat &&
                            !isEmpty(item.seat.seat.fares)
                        "
                        class="text-truncate"
                      >
                        {{ formatCurrency(item.seat.seat.fares[0].total) }}
                      </span>
                      <span v-else> - </span>
                    </template>
                    <template #cell(action)="{item}">
                      <div class="d-flex flex-nowrap justify-content-center">
                        <b-button
                          v-if="item.seat"
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon rounded-circle"
                          @click="handleDeleteSeat(item)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <span v-else> - </span>
                      </div>
                    </template>
                  </b-table>

                  <AppCollapse
                    type="card"
                    class="mb-1"
                  >
                    <AppCollapseItem
                      title
                      isVisible
                      cardClass="border"
                    >
                      <template #header>
                        <div class="text-body fw-700">
                          Chi tiết ghế
                        </div>
                      </template>
                      <div
                        v-for="btn of seatInfoList"
                        :key="btn.title"
                        class="mb-50"
                      >
                        <b-button
                          :variant="btn.variant"
                          :style="['lg', 'xl'].includes(appBreakPoint)
                            ? `width:40px; height:40px; padding: 0;`
                            : `width:28px; height:28px; padding: 0`
                          "
                          :class="btn.class"
                        />
                        <span class="font-weight-bolder ml-75">{{ btn.title }}</span>
                      </div>
                    </AppCollapseItem>
                  </AppCollapse>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="7"
                class="px-0 d-flex-center fit-content"
                style="max-width: 600px;"
              >
                <div v-if="getSeatsMap(segment)">
                  <div class="mb-50 fw-700 font-medium-3 text-center d-flex flex-wrap justify-content-around gap-2">
                    <div class="fw-800">
                      {{ getAirlineNameByCode(getSeatsMap(segment)?.source) }}
                    </div>
                    <div>{{ getAirCraftByIata(getSeatsMap(segment)?.aircraft) }}</div>
                  </div>

                  <b-card
                    v-if="!isEmpty(getSeatsMap(segment)?.seatLayouts)"
                    body-class="pb-0 px-0 mb-1"
                    class="border-secondary-2 shadow"
                    no-body
                  >
                    <div
                      v-for="seatMap of getSeatsMap(segment).seatLayouts"
                      :key="seatMap.id"
                      class="cabinRows mb-1 d-flex flex-column"
                      :style="{
                        alignItems: 'safe center',
                        overflowX: 'auto',
                      }"
                    >
                      <div
                        v-for="groupColumnSeat of seatMap.items"
                        :key="groupColumnSeat.id"
                        class="rowSeats d-flex-center fit-content flex-nowrap mb-50 gap-1 px-50"
                      >
                        <div
                          v-for="blockSeat of groupColumnSeat.items"
                          :key="blockSeat.id"
                          class="blockSeat fw-700 d-flex-center"
                          :style="['lg', 'xl'].includes(appBreakPoint)
                            ? `height:40px; width:40px;`
                            : `width:32px; height:32px;`
                          "
                        >
                          <div v-if="blockSeat.isLabel">
                            {{ blockSeat.name }}
                          </div>

                          <b-button
                            v-else
                            :id="`seat-${blockSeat.id}`"
                            :variant="setVariantSeat(blockSeat, segment)"
                            :class="`p-0 w-100 h-100 d-flex-center fw-700 font-medium-1
                              ${blockSeat?.col && resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', segment.source).includes('ExitRowSeat') ? 'exit_seat_class' : ''}
                            `"
                            :disabled="setDisabledSeat(blockSeat)"
                            @click="handleClickSeatSelect(blockSeat, segment)"
                          >
                            {{ blockSeat.col ? `${blockSeat.row}${blockSeat.col}` : ['EMPTY', 'AISLE'].includes(blockSeat) ? '' : blockSeat }}

                            <b-tooltip
                              v-if="blockSeat.isAvailable"
                              :id="`tooltip-${blockSeat.id}`"
                              :target="`seat-${blockSeat.id}`"
                              triggers="hover"
                              variant="warning"
                              placement="top"
                              boundary="window"
                            >
                              <div class="text-body fw-600">
                                <div class="text-body fw-700 font-medium-1 border-bottom">
                                  {{ $t('flight.seatNumber') }}: {{ blockSeat.name }}
                                </div>
                                <div>{{ $t('flight.seatDescription') }}:</div>
                                <div
                                  v-for="lct of resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', segment.source)"
                                  :key="lct"
                                  class="mb-25"
                                >
                                  - {{ $t(`flight.${lct}`) }}
                                </div>
                                <div class="text-left border-top mt-1">
                                  <h6 class="py-25 mb-0 text-right text-dark font-weight-bolder">
                                    {{ $t('flight.Price') }}:
                                    {{ isEmpty(blockSeat.fares) ? 0 : `${formatCurrency(blockSeat.fares[0].total)}` }}
                                  </h6>
                                </div>
                              </div>
                            </b-tooltip>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>

                <b-card
                  v-else
                  class="height-150 w-100 border-secondary d-flex-center"
                  no-body
                >
                  <b-alert
                    show
                    variant="warning"
                    class="p-1"
                  >
                    <span class="fw-700">{{ $t('flight.notSupportSeat') }}</span>
                  </b-alert>

                  <!-- <b-spinner variant="info">
                    Loading
                  </b-spinner> -->
                </b-card>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTable,
  BButton,
  BTooltip,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
// import { resolveCabinDetails, resolveLocationBySeat } from '@/constants/selectOptions'
import { seatInfoList, resolveLocationBySeat } from '@/components/seat-map/useSeatMapFixRender'

import { formatCurrency } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BRow,
    BCol,
    BTooltip,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BAlert,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),

    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()
    const { isCombination } = toRefs(props)

    const selectedSeatFields = ['passenger', 'seat', 'price', 'action']

    const seatMapData = computed(() => store.getters['app-create-booking/getSeatMapData'] || [])
    const loading = computed(() => store.getters['app-create-booking/getIsRetryLoading'] || false)

    const getAirCraftByIata = code => store.getters['globalConfig/getAirCraftByIata'](code)
    const getAirlineNameByCode = code => store.getters['globalConfig/getAirlineNameByCode'](code)

    // ANCHOR Table Seat Passengers
    function filterSeatBySegment(segment) {
      const dataPaxSeat = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getPaxSeatData`])
      if (!isEmpty(dataPaxSeat.value)) {
        return dataPaxSeat.value.filter(
          s => s.segment.segmentFlights === segment.segmentFlights
            && ((['VN1A'].includes(segment.source) && !isCombination.value)
              ? (s.segment.airlineItineraryId === segment.airlineItineraryId)
              : ['AK'].includes(segment.source)
                ? (s.segment.segmentId === segment.segmentId)
                : true),
        )
      }

      return []
    }

    // NOTE - FLAT SEGMENT
    const flatSegmentSelected = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFlatSegment`])

    const getSeatsMap = segment => {
      if (['VN1A'].includes(segment.source) && !isCombination.value) {
        const result = seatMapData.value.find(item => (item.flights === segment.segmentFlights) && (String(item.itineraryId) === String(segment.airlineItineraryId)))
        return result
      }
      if (['AK', 'VJ'].includes(segment.source)) {
        const result = seatMapData.value.find(item => (item.flights === segment.segmentFlights) && (item.segmentId === segment.segmentId))
        return result
      }
      const result = seatMapData.value.find(item => (item.flights === segment.segmentFlights))
      return result
    }

    // SECTION ====================== Variant, Disabled
    const selectedSeatsDetails = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedSeatsDetails`])

    function setVariantSeat(seat, segment) {
      let variant = 'relief-info'
      const seatName = `${seat.row}${seat.col}`
      if (!seat.isAvailable) {
        variant = 'secondary'
      }
      if (!isEmpty(selectedSeatsDetails.value)) {
        const findExist = selectedSeatsDetails.value.find(ss => {
          const result = ss.seat.segmentFlights === segment.segmentFlights
                && ss.seat.seat.seatName === seatName
                && (['AK', 'VN1A'].includes(segment.source) ? (ss.segment.airlineItineraryId === segment.airlineItineraryId) : true)
          return result
        })
        if (findExist) {
          variant = 'relief-success'
        }
      }
      return variant
    }
    function setDisabledSeat(seat) {
      let setDisabled = false
      if (!seat.isAvailable) {
        setDisabled = true
      }
      return setDisabled
    }
    // !SECTION

    // SECTION ====================== HANDLE SEAT
    function handleClickSeatSelect(seat, segment) {
      const dataSeatSelect = {
        segmentFlights: segment.segmentFlights,
        segmentId: String(segment.airlineSegmentId),
        itineraryId: ['VU'].includes(segment.source)
          ? segment.segmentValue
          : segment.airlineItineraryId,
        source: segment.source,
        airline: segment.airline === 'VZ' ? 'VJ' : segment.airline,
        segmentIds: segment.segmentIds ?? [],
        seat: {
          seatName: `${seat.row}${seat.col}`,
          fares: seat.fares,
          seatValue: seat?.seatValue || null,
        },
      }
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/handleSeatSelect`, dataSeatSelect)
    }

    function handleDeleteSeat({ seat, segment }) {
      const dataSeatSelect = {
        segmentFlights: segment.segmentFlights,
        itineraryId: segment?.airlineItineraryId,
        source: segment.source,
        seat: seat.seat,
      }
      store.dispatch(
        `${FLIGHT_APP_STORE_MODULE_NAME}/handleSeatSelect`,
        dataSeatSelect,
      )
    }
    // !SECTION

    return {
      loading,
      formatCurrency,
      seatInfoList,
      isEmpty,
      getAirCraftByIata,
      getAirlineNameByCode,
      resolveLocationBySeat,

      flatSegmentSelected,
      selectedSeatFields,
      getSeatsMap,
      handleClickSeatSelect,

      handleDeleteSeat,
      setVariantSeat,
      setDisabledSeat,
      filterSeatBySegment,
    }
  },
}
</script>

<style lang="scss" scoped>
.exit_seat_class {
  position: relative;
}
.exit_seat_class::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 6px;
  background-color: #EF5350;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
