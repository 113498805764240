var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.flatSegmentSelected, function (segment, segmentIndex) {
    var _vm$getSeatsMap, _vm$getSeatsMap2, _vm$getSeatsMap3;
    return [_c('b-card', {
      key: segmentIndex,
      staticClass: "border-secondary",
      attrs: {
        "header-bg-variant": "light-info bg-light-primary",
        "header-class": "info",
        "body-class": "py-75 px-0"
      }
    }, [_c('app-collapse', {
      attrs: {
        "type": "card"
      }
    }, [_c('app-collapse-item', {
      attrs: {
        "title": "",
        "body-class": "px-0"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex-between w-100"
          }, [_c('div', [_c('IAmLogoAirline', {
            attrs: {
              "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
              "size": "sm",
              "custom-class": "mr-50"
            }
          }), _c('span', {
            staticClass: "font-weight-bolder font-medium-1 ml-1"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": "), _c('span', {
            staticClass: "text-airline fw-800"
          }, [_vm._v(_vm._s(segment.segmentFlights))])])], 1), _vm.loading ? _c('b-spinner', {
            staticClass: "mr-1",
            attrs: {
              "variant": "info"
            }
          }) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, [['QH'].includes(segment.source) ? _c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm fw-700 d-flex-center my-75",
      attrs: {
        "show": "",
        "variant": "danger"
      }
    }, [_c('b', [_vm._v("Bamboo Airways không hỗ trợ mua ghế khi thanh toán ngay, đại lý vui lòng chọn giữ chỗ nếu cần chọn ghế.")])]) : _vm._e(), _c('b-row', {
      staticClass: "mx-0 pt-1 border-top-secondary justify-content-center"
    }, [_c('b-col', {
      staticClass: "position-relative",
      staticStyle: {
        "max-width": "400px"
      },
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('div', {
      staticClass: "position-sticky",
      staticStyle: {
        "top": "70px"
      }
    }, [_c('b-table', {
      staticClass: "text-center border mb-1",
      attrs: {
        "striped": "",
        "hover": "",
        "responsive": "",
        "items": _vm.filterSeatBySegment(segment),
        "fields": _vm.selectedSeatFields,
        "show-empty": "",
        "empty-text": _vm.$t('noMatchingResult')
      },
      scopedSlots: _vm._u([{
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(data.item.passenger.paxType) + " #" + _vm._s(data.index + 1) + " ")])];
        }
      }, {
        key: "cell(seat)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {}, [item.seat ? _c('b-button', {
            staticClass: "px-75",
            attrs: {
              "variant": "success",
              "disabled": true
            }
          }, [_vm._v(" " + _vm._s(item.seat.seat.seatName) + " ")]) : _c('span', [_vm._v(" - ")])], 1)];
        }
      }, {
        key: "cell(price)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [item.seat && item.seat.seat && !_vm.isEmpty(item.seat.seat.fares) ? _c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.seat.seat.fares[0].total)) + " ")]) : _c('span', [_vm._v(" - ")])];
        }
      }, {
        key: "cell(action)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('div', {
            staticClass: "d-flex flex-nowrap justify-content-center"
          }, [item.seat ? _c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(234, 84, 85, 0.15)',
              expression: "'rgba(234, 84, 85, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "btn-icon rounded-circle",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteSeat(item);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "TrashIcon"
            }
          })], 1) : _c('span', [_vm._v(" - ")])], 1)];
        }
      }], null, true)
    }), _c('AppCollapse', {
      staticClass: "mb-1",
      attrs: {
        "type": "card"
      }
    }, [_c('AppCollapseItem', {
      attrs: {
        "title": "",
        "isVisible": "",
        "cardClass": "border"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body fw-700"
          }, [_vm._v(" Chi tiết ghế ")])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(_vm.seatInfoList, function (btn) {
      return _c('div', {
        key: btn.title,
        staticClass: "mb-50"
      }, [_c('b-button', {
        class: btn.class,
        style: ['lg', 'xl'].includes(_vm.appBreakPoint) ? "width:40px; height:40px; padding: 0;" : "width:28px; height:28px; padding: 0",
        attrs: {
          "variant": btn.variant
        }
      }), _c('span', {
        staticClass: "font-weight-bolder ml-75"
      }, [_vm._v(_vm._s(btn.title))])], 1);
    }), 0)], 1)], 1)]), _c('b-col', {
      staticClass: "px-0 d-flex-center fit-content",
      staticStyle: {
        "max-width": "600px"
      },
      attrs: {
        "cols": "12",
        "lg": "7"
      }
    }, [_vm.getSeatsMap(segment) ? _c('div', [_c('div', {
      staticClass: "mb-50 fw-700 font-medium-3 text-center d-flex flex-wrap justify-content-around gap-2"
    }, [_c('div', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode((_vm$getSeatsMap = _vm.getSeatsMap(segment)) === null || _vm$getSeatsMap === void 0 ? void 0 : _vm$getSeatsMap.source)) + " ")]), _c('div', [_vm._v(_vm._s(_vm.getAirCraftByIata((_vm$getSeatsMap2 = _vm.getSeatsMap(segment)) === null || _vm$getSeatsMap2 === void 0 ? void 0 : _vm$getSeatsMap2.aircraft)))])]), !_vm.isEmpty((_vm$getSeatsMap3 = _vm.getSeatsMap(segment)) === null || _vm$getSeatsMap3 === void 0 ? void 0 : _vm$getSeatsMap3.seatLayouts) ? _c('b-card', {
      staticClass: "border-secondary-2 shadow",
      attrs: {
        "body-class": "pb-0 px-0 mb-1",
        "no-body": ""
      }
    }, _vm._l(_vm.getSeatsMap(segment).seatLayouts, function (seatMap) {
      return _c('div', {
        key: seatMap.id,
        staticClass: "cabinRows mb-1 d-flex flex-column",
        style: {
          alignItems: 'safe center',
          overflowX: 'auto'
        }
      }, _vm._l(seatMap.items, function (groupColumnSeat) {
        return _c('div', {
          key: groupColumnSeat.id,
          staticClass: "rowSeats d-flex-center fit-content flex-nowrap mb-50 gap-1 px-50"
        }, _vm._l(groupColumnSeat.items, function (blockSeat) {
          return _c('div', {
            key: blockSeat.id,
            staticClass: "blockSeat fw-700 d-flex-center",
            style: ['lg', 'xl'].includes(_vm.appBreakPoint) ? "height:40px; width:40px;" : "width:32px; height:32px;"
          }, [blockSeat.isLabel ? _c('div', [_vm._v(" " + _vm._s(blockSeat.name) + " ")]) : _c('b-button', {
            class: "p-0 w-100 h-100 d-flex-center fw-700 font-medium-1\n                            ".concat(blockSeat !== null && blockSeat !== void 0 && blockSeat.col && _vm.resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', segment.source).includes('ExitRowSeat') ? 'exit_seat_class' : '', "\n                          "),
            attrs: {
              "id": "seat-".concat(blockSeat.id),
              "variant": _vm.setVariantSeat(blockSeat, segment),
              "disabled": _vm.setDisabledSeat(blockSeat)
            },
            on: {
              "click": function click($event) {
                return _vm.handleClickSeatSelect(blockSeat, segment);
              }
            }
          }, [_vm._v(" " + _vm._s(blockSeat.col ? "".concat(blockSeat.row).concat(blockSeat.col) : ['EMPTY', 'AISLE'].includes(blockSeat) ? '' : blockSeat) + " "), blockSeat.isAvailable ? _c('b-tooltip', {
            attrs: {
              "id": "tooltip-".concat(blockSeat.id),
              "target": "seat-".concat(blockSeat.id),
              "triggers": "hover",
              "variant": "warning",
              "placement": "top",
              "boundary": "window"
            }
          }, [_c('div', {
            staticClass: "text-body fw-600"
          }, [_c('div', {
            staticClass: "text-body fw-700 font-medium-1 border-bottom"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatNumber')) + ": " + _vm._s(blockSeat.name) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t('flight.seatDescription')) + ":")]), _vm._l(_vm.resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', segment.source), function (lct) {
            return _c('div', {
              key: lct,
              staticClass: "mb-25"
            }, [_vm._v(" - " + _vm._s(_vm.$t("flight.".concat(lct))) + " ")]);
          }), _c('div', {
            staticClass: "text-left border-top mt-1"
          }, [_c('h6', {
            staticClass: "py-25 mb-0 text-right text-dark font-weight-bolder"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.Price')) + ": " + _vm._s(_vm.isEmpty(blockSeat.fares) ? 0 : "".concat(_vm.formatCurrency(blockSeat.fares[0].total))) + " ")])])], 2)]) : _vm._e()], 1)], 1);
        }), 0);
      }), 0);
    }), 0) : _vm._e()], 1) : _c('b-card', {
      staticClass: "height-150 w-100 border-secondary d-flex-center",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-alert', {
      staticClass: "p-1",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s(_vm.$t('flight.notSupportSeat')))])])], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }